import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/how-to-lint-staged-git-files",
  "date": "2021-08-04",
  "title": "How to lint staged Git files",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "We use linting to automate code formatting issues, to fix syntax errors and coding style. For example, you may have forgotten a semi-colon during aor bug fix you may have accidentally left a console.log statement."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why should we use Linting?`}</h2>
    <p>{`Time spent on quality assurance and control can be reduced with automated linting. It also makes sure developers of the code's conformity to your coding standards.`}</p>
    <Message type="info" title="Montitoring for coding errors" content=" A common approach is to have the linting tool running in watch mode within a terminal to monitor and report errors whenever you save a file" mdxType="Message" />
    <h2>{`The Problem With Watch mode`}</h2>
    <p>{`Popular linting tools include `}<inlineCode parentName="p">{`ESLint`}</inlineCode>{` for JavaScript/TypeScript and `}<inlineCode parentName="p">{`stylelint`}</inlineCode>{` for CSS/SCSS/Sass/Less/SugarSS. Running Lint with watch mode tends to be distracting, with your attention switching back-and-forth between the terminal and code editor.`}</p>
    <p>{`Ideally, you should run the linter after you finish coding, but you may forget to run the linter and immediately commit your changes.`}</p>
    <p>{`So there should ideally be an automated solution that hooks into your Git commit lifecycle.`}</p>
    <h2>{`lint-staged For Linting`}</h2>
    <p>{`With lint-staged, `}<inlineCode parentName="p">{`git commit`}</inlineCode>{` command automatically runs the linter against files staged for commit, letting you continue working on the application code without any interruptions.`}</p>
    <p>{`Once you are done with the code, you stage the modified files and run the linter before committing the changes.`}</p>
    <p>{`You configure lint-staged to run a specific linter command against files that match a glob pattern. For JavaScript files (`}<em parentName="p">{`.js), ESLint is the linter to run against those files. For CSS files (`}</em>{`.css), stylelint is the appropriate linter to run against those files.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git commit
`}</code></pre>
    <p>{`If any files fail to pass, then they are not committed to the remote repository. Before the files can be committed, you must first resolve the errors raised by the linter. For projects with many contributors, linting enforces the project's coding conventions despite each contributor having their own set of opinions and conventions.`}</p>
    <h2>{`Why lint-staged is different from `}<strong parentName="h2">{`pre-commit`}</strong>{` And `}<strong parentName="h2">{`Husky`}</strong>{`?`}</h2>
    <p>{`Unlike lint-staged, the closely-related tools pre-commit and Husky lint all files, not just staged files. As your project grows, linting files that have not been modified becomes inefficient. It increases development time from all the redundant linting.`}</p>
    <Message type="info" title="Husky vs pre-commit vs lint-staged" content="Husky supports all Git hooks, whereas pre-commit and lint-staged support only the pre-commit Git hook. Nevertheless, each library allows you to run any number of custom scripts, defined in package.json, when a hook is invoked. Git hooks, such as pre-commit, are invoked by an event such as executing the git commit command." mdxType="Message" />
    <p>{`lint-staged uses Husky under-the-hood.`}</p>
    <h2>{`Add lint-staged to your project`}</h2>
    <p>{`Now let's see a practical example of how to integrate lint-staged into your project.`}</p>
    <h3>{`Install lint-staged`}</h3>
    <p>{`To get started, check that your project already has `}<em parentName="p">{`ESLint`}</em>{`, `}<em parentName="p">{`stylelint`}</em>{` or `}<em parentName="p">{`Prettier`}</em>{` configured (either via a .xxxrc configuration file or a key inside package.json). Otherwise, the following error message is shown during the installation of lint-staged.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pre"
      }}>{`Cannot add lint-staged: only eslint, \\
stylelint, prettier or custom rules \\
are supported.
`}</code></pre>
    <p>{`To install lint-staged, run the following command within the root directory of your project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`npx mrm@2 lint-staged
`}</code></pre>
    <p><em parentName="p">{`mrm`}</em>{` is a command line tool for automating and managing project configurations.`}</p>
    <p>{`If you are prompted with the following message, then press y to proceed with the installation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pre"
      }}>{`Need to install the following packages:
  mrm@2
Ok to proceed? (y)
`}</code></pre>
    <p>{`This command creates a `}<inlineCode parentName="p">{`./husky`}</inlineCode>{` directory.
Adds the husky and lint-staged packages to `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` as dev dependencies.
Adds a lint-staged property to package.json.
If lint-staged finds a `}<inlineCode parentName="p">{`.eslintrc.js`}</inlineCode>{` configuration file, then a `}<inlineCode parentName="p">{`*.js`}</inlineCode>{` property is added to the lint-staged object.`}</p>
    <p>{`Here's what this looks like in the package.json file.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "lint-staged": {
    "*.js": "eslint --cache --fix"
  }
}
`}</code></pre>
    <p>{`Anytime a JavaScript file is staged for commit, lint-staged runs the ESLint linter with the --cache and --fix flags:`}</p>
    <p>{`the `}<em parentName="p">{`--cache`}</em>{` flag tells ESLint to only check changed files
the `}<em parentName="p">{`--fix`}</em>{` flag tells ESLint to automatically fix problems when possible.
If lint-staged finds a .prettierrc configuration file, then a `}{`*`}{`.{js,css,md} property is added to the lint-staged object.`}</p>
    <p>{`Here's what this looks like in the package.json file.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "lint-staged": {
    "*.{js,css,md}": "prettier --write"
  }
}
`}</code></pre>
    <p>{`Anytime a JavaScript, CSS or Markdown file is staged for commit, lint-staged runs Prettier to properly format the contents of the file. This ensures the files are formatted even if a contributor does not have the Prettier plugin installed in their IDE to automatically format the files when saved.`}</p>
    <p>{`Adding Testing And Other Scripts
Suppose you want the staged JavaScript files to pass unit tests written with the Jest testing framework. Adding a new script is relatively straight-forward. Update the value of the `}{`*`}{`.js key to an array that contains a list of scripts to run against the staged JavaScript files.`}</p>
    <p>{`As an example, suppose we want to run Jest tests with the jest `}<em parentName="p">{`--passWithNoTests`}</em>{` command. The --passWithNoTests option allows the test suite to pass even if there are no test files present and you plan to add unit tests in the future.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "lint-staged": {
    "*.js": [
      "eslint --cache --fix",
      "jest --passWithNoTests"
    ]
  }
}
`}</code></pre>
    <p>{`These scripts run in sequence from the first listed item to the last listed item. If a single command fails, then the entire commit is rejected.`}</p>
    <p>{`To dive more into linting, check out the `}<a parentName="p" {...{
        "href": "https://github.com/okonet/lint-staged"
      }}>{`lint-staged Documentation`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      